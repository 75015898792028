import React, { useCallback, useContext } from "react";
import PortableText from "./portableText";
import { cn, toPlainText } from "../lib/helpers";
import { Byline } from "./card/byline";
import getYouTubeId from "get-youtube-id";
import YouTube from "react-youtube";
import { Podcast, SectionWrapper } from "./sections";
import { ShareWidgets } from "./share-widgets";
import { pageDocPath } from "../../helpers";
import { CardGrid } from "./card/card-grid";
import { SiteContext } from "./global/site-context";
import { BackToZone } from "./back-to-zone";
import { Pills } from "./card/pills";
import { PublishDate } from "./card/publish-date";
import useMedia from "use-media";
import { Promo } from "./sections/promo";
import { Button } from "./button";
import { ArrowLeft } from "./icon/arrow-left";
import { ImageBuilder } from "./global/image-builder";
import { PodcastLinks } from "./sections/snowflake/podcast";

import * as styles from "./post-page.module.css";
import { CardContext } from "./card/context";

const HERO_IMAGE_WIDTH = 640;
const PAGE_CONTAINER = "narrow";

const PODCAST_RELATED_TAGS = [
  "17e46daf-7cde-44a3-b8b3-7ffcf6c7b86b",
  "da38ee48-4ccb-4632-aed4-fae6aeb77b8c",
  "e398c056-1d60-450c-9156-6f34a1808f89",
  "62c8d09b-665d-4ecb-bc7b-e6769e14963a",
];

const PODCAST_YT_LIST_ID = "PLKDS0si7azFltUVuYOUW1aby9NVevUsA1";

export const BlogPostPage = ({
  title,
  authors,
  _rawBody,
  _rawExcerpt,
  mainImage,
  mainVideo,
  cardImage,
  hideMainMedia,
  slug,
  suggest,
  categories,
  readingTimeInMinutes,
  promo,
  mainAudio,
  duration,
  recentPosts
}) => {
  const site = useContext(SiteContext);

  const noMedia = hideMainMedia || (!mainImage && !mainVideo);
  const hasVideo = !hideMainMedia && mainVideo && mainVideo.url;
  const isLarge = useMedia({ minWidth: "1024px" });
  const podcastRelated =
    // site.isActive("podcastEmbeds") &&
    categories.some((c) => PODCAST_RELATED_TAGS.includes(c._id));

  const Share = useCallback(
    () => (
      <ShareWidgets
        title={title}
        excerpt={toPlainText(_rawExcerpt)}
        path={pageDocPath({ _type: "post", slug })}
        vertical={isLarge}
        size={24}
      />
    ),
    [title, _rawExcerpt, slug, site, isLarge]
  );

  // @todo Podcast embed

  return (
    <>
      <div className="z-40 left-0 top-0 xl:pl-8 lg:fixed pt-8 lg:pt-20">
        <SectionWrapper padding="none" container="lg">
          <div className="mb-32 hidden lg:block"></div>
          {categories.find(
            (c) => c._id === "17e46daf-7cde-44a3-b8b3-7ffcf6c7b86b"
          ) && (
              <div className="mb-6">
                <Button
                  text="Explore series"
                  href="/podcast/"
                  icon={<ArrowLeft size="12" />}
                  bgColor="bg-dark-10"
                />
              </div>
            )}

          <BackToZone to="/blog" text={site.token("backToBlog")} />

          <h3 className="text-sm mb-4">{readingTimeInMinutes} min read</h3>
          {isLarge && (
            <div className="mb-12">
              <Share />
            </div>
          )}
        </SectionWrapper>
      </div>

      <SectionWrapper container={PAGE_CONTAINER} padding="top">
        <div className="z-20 relative">
          {hasVideo && (
            <YouTube
              videoId={getYouTubeId(mainVideo.url)}
              className="rounded-xl overflow-hidden"
              containerClassName="w-full"
              opts={{
                playerVars: {
                  // list: podcastRelated ? PODCAST_YT_LIST_ID : null,
                  rel: 0,
                },
              }}
            />
          )}
          {!hideMainMedia &&
            !mainVideo &&
            (mainImage?.asset || cardImage?.assset) && (
              <div className="block relative rounded-2xl overflow-hidden">
                <ImageBuilder
                  image={cardImage || mainImage}
                  width={HERO_IMAGE_WIDTH}
                  height={
                    mainImage?.asset
                      ? Math.round(
                        HERO_IMAGE_WIDTH /
                        mainImage.asset?.metadata?.dimensions?.aspectRatio
                      )
                      : null
                  }
                  alt={cardImage ? title : mainImage.alt}
                />
              </div>
            )}
        </div>

        {noMedia && <h1 className="text-5xl mt-2">{title}</h1>}

        <div className="flex gap-1 items-center mt-4 mb-2">
          <CardContext.Provider value={{ hideType: true }}>
            {categories && <Pills tags={categories} showSubTags={true} />}
          </CardContext.Provider>
          <div className="inline-block ml-1 text-sm text-gray-600">
            <PublishDate />
          </div>
        </div>

        {!noMedia && (
          <h1 className="text-5xl mt-2">
            {`${title}${hasVideo ? " [video]" : ""}`}
          </h1>
        )}

        {podcastRelated && (
          <PodcastLinks
            className="p-2 mt-4 border border-dark-10 rounded-xl bg-dark-2"
            hideYouTube={true}
          />
        )}

        {!isLarge && (
          <div className="mt-6">
            <Share />
          </div>
        )}
      </SectionWrapper>

      {/* {companies && companies.map((c) => <p>{c.title}</p>)} */}

      <SectionWrapper container={PAGE_CONTAINER} padding="tight">
        {authors.length > 0 && (
          <div className="pt-3 pb-6 md:pt-0">
            <Byline />
          </div>
        )}
        <div className={cn("leading-relaxed", styles.mainContent)} id="article">
          {_rawBody && <PortableText blocks={_rawBody} />}
        </div>
      </SectionWrapper>

      {promo && (
        <SectionWrapper container={PAGE_CONTAINER} padding="tight">
          <Promo {...promo} />
        </SectionWrapper>
      )}

      {site.isActive("suggestions") && suggest && (
        <SectionWrapper
          header={podcastRelated ? "Listen to the podcast" : site.token("suggestions")}
          anchor="more"
          container={PAGE_CONTAINER}
          background="lavenderUltraLight"
        >
          {podcastRelated ? (
            <div className="mb-8">
              <Podcast />
            </div>
          ) : (
            <CardGrid
              nodes={suggest.concat(recentPosts).slice(0, 4)}
              browseMoreHref="/blog"
              maxColumns={2}
              allowSummary={true}
              showSummary={true}
              panels={true}
              attribution={{ action: "suggestion", label: "post" }}
              makeThumbnail={true}
            />
          )}
        </SectionWrapper>
      )}
    </>

  );
};
